import React from "react";

import "./Home1.css";
// export default function Home1() {
//   return (
//     <div id="Home1" className="usecase row-padding">
//       <div className="usecase----container">
//         <h2 style={{marginBottom:'1%',paddingTop:'1.5%',paddingLeft:'1%'}}>AI for Data Modernization </h2>
//         <div style={{ display: 'flex', justifyContent: 'space-between',height:'80%' }}>
//           <div className="rectangle-list col-lg-5">
//             <div className="usecase-text">
//               <p style={{paddingLeft:'2%'}}>
//                 AI augmenting data teams and accelerating implementation of AI use
//                 cases
//               </p>
//             </div>
//             <img src="https://legoai.com/assets/images/AI_for_Data_Modernization2.png" alt="img" />
//             {/* <div className="rectangle-first">
//             <p>Data Modeler</p>
//             <div className="in-circle">
//               <img src="/assets/Icons/It.png" alt="Data Modeler" />
//               <div className="datab">
//                 <img
//                   src="/assets/Icons/database.png"
//                   alt="Data Modeler"
//                   loading="lazy"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="rectangle-second">
//             <p>Data Steward</p>
//             <div className="in-circle1">
//               <img src="/assets/Icons/st.png" alt="Data Steward" />
//               <div className="db">
//                 <img src="/assets/Icons/db.png" alt="Data Steward" />
//               </div>
//             </div>
//           </div>

//           <div className="rectangle-third">
//             <p>Data Enginner</p>
//             <div className="in-circle2">
//               <img src="/assets/Icons/dataeng.png" alt="Data Enginner" />
//             </div>
//           </div> */}
//           </div>
//           <div className="home-text-section-right col-lg-6">
//               <img
//                 src="https://legoai.com/assets/images/AI_for_Data_Modernization.png"
//                 alt="AI for Data Modernization"
//                 style={{margin:'auto 0px'}}
//               />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }



export default function Home1() {

  let imageStyles = {
    marginTop:"2%",
    marginBottom:"2%",
  }
  return (
    <div id="Home1" className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
          <h1 style={{marginBottom:'1%',paddingTop:'1.5%',paddingLeft:'0%'}}>AI for Data Modernization </h1>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
          <div className="usecase-text">
              <p style={{paddingLeft:'0%'}}>
                AI augmenting data teams and accelerating implementation of AI use
                cases
              </p>
            </div>
        </div>


        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <img
            src="https://legoai.com/assets/images/AI_for_Data_Modernization.png"
            alt="AI for Data Modernization"
            style={{
              margin:'2% auto'
            }}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <img 
            src="https://legoai.com/assets/images/AI_for_Data_Modernization2.png" 
            alt="img"
            style={{
              margin:'2% auto'
            }} 
          />
        </div>

      </div>
    </div>
  );
}


