import React from "react";
import "./Banner.css";
import { AzureMP } from 'react-azure-mp'
// import AzureBlobVideo from "../azureMP/AzureBlobVideo";

const url = "https://websitevideostreaming-websiteassets-usno1.streaming.media.azure.net/222e6e6e-f986-4749-9289-884bf36023a1/LEGOAI_Final_website_landing.ism/manifest(format=mpd-time-cmaf)"

function BannerVideo(props) {
  
  // return (
  //   <div id="banner" className="Banner-video">
  //     <video autoPlay loop muted playsInline>
  //       <source src={props.video} type="video/mp4" />
  //     </video>
  //   </div>
  // );
  return (
    <AzureMP
      style={{
        height:"400px",
        overflow:"hidden"
      }}
      id="bannerVideoWrapper"
      adaptRatio={[4, 2]}
      height={400}
      options={{ 
          height:400, 
          controls: false, 
          autoplay: true, 
          autoPlay: true, 
          muted:true,
          loop:true,
      }}
      skin="amp-flush"
      src={[{src: url, type: "application/vnd.ms-sstr+xml" }]}
    />
    // <AzureBlobVideo 
    // accountName=""
    // accountKey="" 
    // containerName="" 
    // blobName=""
    // />
  );
}

export default BannerVideo;
