import React from "react";

import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const _data = {
  poster:
    "https://legoai.com/assets/images/AI_for_Data_Modernization.png",
    poster_mobile:"https://legoai.com/assets/images/ONTOCRAFT_MOBILE.png",
  title: <div>Ontocraft</div>,
  slides: [
    {
      title: "Define",
      desc: "Logincal Grouping of Data Assets Using Algorithmic Technique",
      src: "https://filesamples.com/samples/video/ogv/sample_640x360.ogv",
    },
    {
      title: "Title2",
      desc: "desc",
      src: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4",
    },
    {
      title: "Title3",
      desc: "desc",
      src: "https://filesamples.com/samples/video/ogv/sample_640x360.ogv",
    },
    {
      title: "Title4",
      desc: "desc",
      src: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4",
    },
  ],
};
function WranglerSlider(props) {
  const issmall = window.innerWidth <= 1024;
  let [slide, setSlide] = React.useState( (issmall)? 1 : 0 );
  let data = props.data || _data || {};
  const defaultSlideClickStyle = {
    cursor: "pointer",
    margin: "0px 10px",
  };
  return (
    <div
      id={props.id || (Math.random() + 1).toString(36).substring(7)}
      style={{
        height: (issmall)?"auto":"100%",
        width: "100%",
        //background:"linear-gradient(30deg, rgba(11,48,237,1) 7%, rgba(3,24,105,1) 7%, rgba(1,19,80,1) 87%)",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'space-between'
      }}
    >
      <div>
        <h1>{data.title}</h1>
      </div>

      {/* start of main section */}
      {slide === 0 ? (
        <MyVideo data={data} issmall={issmall} />
      ) : (
        <MyImage data={data} issmall={issmall} />
      )}


      {/* start od botom section */}
      {
        // hiding this section for mobile view
        (issmall)? null: 
      <div
        style={{
          background: "rgba(100,100,100,0)",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
          height: '5vh',
        }}
      >
        <span
          style={{
            ...defaultSlideClickStyle,
            color: slide === 0 ? "#d117ff" : "#fff",
          }}
          onClick={(e) => {
            setSlide(0);
          }}
        >
          <HorizontalRuleIcon />
        </span>
        <span
          style={{
            ...defaultSlideClickStyle,
            color: slide === 1 ? "#d117ff" : "#fff",
          }}
          onClick={(e) => {
            setSlide(1);
          }}
        >
          <HorizontalRuleIcon />
        </span>
      </div>
      }
    </div>
  );
}

const MyImage = (props) => {
  let data = props.data || {};

  return (
    <div
      style={{
        height: (props.issmall)?"auto":"80vh",
        width: "100%",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
        src={ 
          props.issmall? 
            data["poster_mobile"] || data["poster"] || "" 
            :
            data["poster"] || ""
          }
        alt="LegoAiOntocraft"
      />
    </div>
  );
};

const MyVideo = (props) => {
  const videoRef = React.useRef();
  let [active, setActive] = React.useState(0);

  const issmall = props.issmall;

  let data = props.data || {};

  React.useEffect(() => {
    videoRef.current?.load();
  });
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "75%",
        flexDirection: "row",
      }}
    >
      <div>
        <div
          style={{
            visibility: issmall ? "hidden" : "visible",
            float: "right",
            // position:"relative",
            // top:0,
            // left:"1%",
            background: "#ccc",
            height: "100%",
            width: "10px",
            // float:"right",
            borderRadius: "5px",
            zIndex: 10,
            marginLeft:'4px',
            marginRight:'8px',
          }}
        >
          <div
            style={{
              height:
                ((window.innerHeight * 0.75) / data["slides"].length) *
                (active + 1) +
                "px",
              background: "#d117ff",
              borderRadius:'5px',
            }}
          ></div>
        </div>
      </div>

      <div>
        <div className="container-fluid" style={{height:'100%'}}>
          <div className="row" style={{height:'100%'}}>
            {/* <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                    <div
                      style={{
                        display:(window.innerWidth <= 750)?"none":"block",
                        background:"#ccc",
                        height:"100%",
                        width:"5px",
                        float:"right",
                        borderRadius:"3px",
                      }}
                      >
                        <div
                          style={{
                            height:window.innerHeight*0.90/(data["slides"].length)*(active+1) + "px",
                            background:"#d117ff"
                        
                          }}
                        >
                        </div>
                        
                    </div>
                  </div> */}
            <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12" style={{height:'100%'}}>
              <div
                style={{
                  // height:"90vh",
                  height: "100%",
                  display: "flex",
                  // flexDirection:"column",
                  flexDirection: "column",
                  // marginBottom: issmall ? "10%" : "0",
                  justifyContent: "space-between",
                  // paddingLeft:(issmall)?"auto":"20%",
                  textAlign: issmall ? "center" : "left",
                }}
              >
                {data["slides"].map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={(e) => {
                        setActive(index);
                      }}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        // height:'fit-content',
                        // alignItems:"center",
                        // justifyContent:"center"
                      }}
                    >
                      <div
                        style={{
                          textWeight: "bold",
                          color: index === active ? "#fff" : "#ccc",
                          fontSize: "1.2em",
                          // textDecoration:(index===active)?"underline #d117ff":"none",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bolder",
                        }}
                      >
                        {item["title"]}
                      </div>

                      {issmall ? null : (
                        <p
                          style={{
                            fontSize: "1em",
                            textAlign: "left",
                            color: index === active ? "#fff" : "#ccc",
                            marginBottom:0,
                          }}
                        >
                          {item["desc"]}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              // className="col-lg-10 col-md-9 col-sm-12 col-xs-12"
              className="col-lg-10 col-md-9 col-sm-12 col-xs-12"
              style={{
                height: "100%",
                // background:"#000"
              }}
            >
              <video
                ref={videoRef}
                id="player"
                muted={true}
                // controls={true}
                autoPlay={true}
                poster="https://thumbs.gfycat.com/CandidRichAntbear-max-1mb.gif"
                // loop={true}
                onEnded={e=>{
                  if(active < data["slides"].length-1){
                    setActive(active+1)
                  }else{
                    setActive(0)
                  }
                }}
                style={{
                  flex: 1,
                  height: "100%",
                  objectFit: "contain",
                  width: "100%",
                  // margin:'0 auto',
                  // boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
                  // boxShadow:"0px 0px 10px #0070c0"
                }}
              >
                <source src={data["slides"][active]["src"] || ""}></source>
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WranglerSlider;
