/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Ontocraft.css";
import WranglerSlider from "../Stepper/WranglerSlider";
const _data = {
  poster_mobile:"https://legoai.com/assets/images/ONTOCRAFT_MOBILE.png",
  poster:
    "https://legoai.com/assets/images/AI_Augmented_Data_Product_Studio.png",

  title: (
    <div className="onto-logo">
      <img
        src="https://legoai.com/assets/images/Ontocraft_logo.png"
        alt="OntoCraft,AI Augmented Data Product Studio"
        loading="lazy"
        style={{
          paddingLeft:"0%"
        }}
      />
      <h2>AI Augmented Data Product Studio</h2>
    </div>
  ),
  slides: [
    {
      title: "DEFINE",
      desc: "Logical grouping of data assets to form data domains.",
      src: "https://legoai.com/assets/Ontocraft_topology.mp4 ",
    },
    {
      title: "DESIGN",
      desc: "AI based mechanism to identify relationships among data assets.",

      src: "https://legoai.com/assets/Ontocraft_ontology.mp4",
    },
    {
      title: "TRANSFORM",
      desc: "Pre-configured functions to build features, DQ & join recipes.",
      src: "https://legoai.com/assets/Ontocraft_wrangler.mp4",
    },
    {
      title: "PUBLISH",
      desc: "AI based business glossary generation & access controls.",
      src: "https://legoai.com/assets/Ontocraft_governance.mp4",
    },
  ],
};

function Ontocraft(props) {
  const srcImage = "/assets/images/AI_Augmented_Data_Product_Studio.png";
  return <WranglerSlider data={_data} id={"OntoCraft"} />;
}

export default Ontocraft;
