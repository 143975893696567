/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./OntoSphere.css";
import WranglerSlider from "../Stepper/WranglerSlider";
const _data = {
  poster_mobile:
    "https://legoai.com/assets/images/ONTOSPHERE_MOBILE.png",
  poster:
    "https://legoai.com/assets/images/AI_Augmented_Data_Consumer_Hub.png",
  title: (
    <div className="sphere-logo">
      <img
        src="https://legoai.com/assets/images/Ontosphere_logo.png"
        alt="OntoSphereLogo,AI Augmented Data Consumer Hub"
        loading="lazy"
        style={{
          paddingLeft:"0%"
        }}
      />
      <h2> AI Augmented Data Consumer Hub</h2>
    </div>
  ),
  slides: [
    {
      title: "EXPLORE",
      desc: "Data Products catalogue to explore & search data ontologies.",

      src: "https://legoai.com/assets/ontosphere_marketplace.mp4",
    },
    {
      title: "SELECT",
      desc: "No code / low code interface to build anaytical data model.",

      src: "https://legoai.com/assets/ontosphere_ontology.mp4",
    },
    {
      title: "REVIEW",
      desc: "Examine data profiling metrics and summary statistics.",
      src: "https://legoai.com/assets/ontosphere_mycart.mp4",
    },
    {
      title: "SERVE",
      desc: "Data Monetization model to serve data on demand.",
      src: "https://legoai.com/assets/ontosphere_checkout.mp4",
    },
  ],
};

function OntoSphere(props) {
  const imageUrl = "/assets/images/AI_Augmented_Data_Consumer_Hub.png";

  return <WranglerSlider data={_data} id={"OntoSphere"} />;
}

export default OntoSphere;
