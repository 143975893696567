/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Arcit.css";

function Archi() {
  return (
    <div id="Architecture" className="Architect row-padding">
      <h2
        style={{
          marginBottom:"1.5rem"
        }}
      >Technical Architecture</h2>
      <div className="Arch-img-container">
        <img
          src="https://legoai.com/assets/images/Architecture.png"
          alt="archi"
        />
      </div>
    </div>
  );
}

export default Archi;
